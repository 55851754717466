@import '../../styles/varibale.scss';
@import '../../styles/mixin.scss';

.brands {
    
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @media(max-width: $breakpoints-5) {
            flex-direction: column;
            align-items: flex-start;
            & > *:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        position: relative;
        @media(max-width: $breakpoints-5) {
            position: absolute;
            top: 0;
            right: 0;
        }
        .swiper-button-prev, .swiper-button-next {
            position: static;
            margin: auto 15px 0px 15px;
            cursor: pointer;
            opacity: 1;
            width: auto;
            height: auto;
            @media(max-width: $breakpoints-5) {
                margin: 0 10px 0px 10px;
                svg {
                    width: 12px;
                }
            }
            &:hover {
                svg {
                    fill: #00348C;
                    opacity: 1;
                }
            }
            svg {
                transition: var(--transition);
                fill: #00348C;
                opacity: 0.2;
            }
        }
    }
    &__row {
        display: flex;
        @media(max-width: $breakpoints-4) {
            flex-direction: column-reverse;
        }
    }

    &__banner {
        background-color: var(--helper-color-danger);
        flex: 0 0 424px;
        margin-right: 35px;
        padding: 50px 36px 36px 36px;
        @media(max-width: $breakpoints-2) {
            margin-right: 20px;
            padding: 30px;
        }
        @media(max-width: $breakpoints-3) {
            flex: 0 0 380px;
        }
        @media(max-width: $breakpoints-4) {
            margin-right: 0;
            flex: 1 1 100%;
            margin-top: 20px;
            padding: 20px 15px;
        }
    }
    &__banner-logo {
        margin-bottom: 50px;
        max-width: 291px;
        @media(max-width: $breakpoints-4) {
            max-width: 200px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__banner-text {
        color: var(--color-secondary);
        line-height: 150%;
        span {
            font-weight: 700;
        }
    }
    &__category {
        min-width: 0px;
        overflow: hidden;
        flex: 1 1 auto;
    }
}

// Card brands
.card-brands {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 237px;
    border: 1px solid var(--helper-color-danger);
    padding: 20px;
    font-size: 24px;
    @media(max-width: $breakpoints-2) {
        font-size: 20px;
    }
    @media(max-width: $breakpoints-4) {
        min-height: 120px;
        padding: 10px;
        font-size: 16px;
    }
    &:hover {
        .card-brands__text {
            color: var(--color-hover);
        }
    }
    &__text {
        color: var(--color-primary);
        text-decoration: underline;
        max-width: 170px;
        text-align: center;
        line-height: 130%;
        transition: var(--transition);
    }
}