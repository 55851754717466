@import '../../styles/varibale.scss';
.hero {
    position: relative;
    height: 37.5vw;
    &__slider {
      height: 100%;
      .swiper-pagination {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        bottom: 50px;
        @media(max-width: $breakpoints-1) {
            bottom: 30px;
        }
        @media(max-width: $breakpoints-5) {
            bottom: 10px;
        }
    }
    .swiper-pagination-bullet {
        display: inline-block;
        background-color: var(--helper-color-any);
        cursor: pointer;
        &-active {
            background-color: var(--helper-color-light);
        }
        &:not(:last-child) {
            margin-right: 24px;
            @media(max-width: $breakpoints-1) {
                margin-right: 15px;
            }
        }
    }
      .swiper-button-prev {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 84px;
        height: 84px;
        border-radius: 50%;
        overflow: hidden;
        background-color: var(--helper-color-white);
        transition: var(--transition);
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
        background-image: url(./arrow/arrow-left.svg);
        background-repeat: no-repeat;
        background-position: center;
        @media(max-width: $breakpoints-1) {
            width: 54px;
            height: 54px;
            svg {
                width: 12px;
            }
        }
        @media(max-width: $breakpoints-5) {
            width: 35px;
            height: 35px;
            svg {
                width: 7px;
            }
        }
        &:hover {
            background-color:#e9e7e7;
        }
        left: 60px;
        @media(max-width: $breakpoints-1) {
            left: 30px;
        }
        @media(max-width: $breakpoints-5) {
            left: 10px;
        }
    }
    .swiper-button-next {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 84px;
        height: 84px;
        border-radius: 50%;
        overflow: hidden;
        background-color: var(--helper-color-white);
        transition: var(--transition);
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
        background-image: url(./arrow/arrow-rigth.svg);
        background-repeat: no-repeat;
        background-position: center;
        @media(max-width: $breakpoints-1) {
            width: 54px;
            height: 54px;
            svg {
                width: 12px;
            }
        }
        @media(max-width: $breakpoints-5) {
            width: 35px;
            height: 35px;
            svg {
                width: 7px;
            }
        }
        &:hover {
            background-color:#e9e7e7;
        }
        right: 60px;
        @media(max-width: $breakpoints-1) {
            right: 30px;
        }
        @media(max-width: $breakpoints-5) {
            right: 10px;
        }
    }
    }
    .swiper{
        height: 100%;
        .swiper-slide {
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}