@import './styles/varibale.scss';
@import './styles/any-components.scss';
#root {
    height: 100%;
}
.wrapper{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // overflow: hidden;
    @media(max-width: $breakpoints-5) {
        padding-bottom: 40px;
    }
}

.main {
    flex-grow: 1;
}
input[type="date"]{
    display:block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
}
select.form-control {
    appearance: none;
    position: relative;
    background-size: 12px 10px;
    // background: url(../img/select-arrow.svg) no-repeat right 0.75rem center;
    color: #404040;
    font-family: var(--fontFamilyPrimary);
    cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.scroll {
    scrollbar-color:  #c4c4c4 #eff3f9;
    scrollbar-width: thin;
    -webkit-overflow-scrolling:touch;
}
.scroll::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: #eff3f9;
    width: 3px;
}
.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c4c4c4;
}
.bg-cover {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


.container{
    width: 100%;
    max-width: 1920px;
    padding: 0px 60px;
    margin: 0px auto;
    @media(max-width: $breakpoints-1) {
        padding: 0 30px;
    }
    @media (max-width: $breakpoints-3) {
        padding: 0 15px;
    }
}
.container-second {
    width: 100%;
    max-width: 1920px;
    padding: 0px 0px 0px 60px;
    margin: 0px auto;
    @media(max-width: $breakpoints-1) {
        padding: 0 30px;
    }
    @media (max-width: $breakpoints-3) {
        padding: 0 15px;
    }
}
.container-custom {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}
.mb-small {
    margin-bottom: 20px;
}
.mb-med {
    margin-bottom: 44px;
    @media(max-width: $breakpoints-5) {
       margin-bottom: 30px;
    }
}
.mb-big {
    margin-bottom: 90px;
    @media(max-width: $breakpoints-5) {
        margin-bottom: 40px;
    }
}
.mr-small {
    margin-right: 10px;
}
.ind-small {
    padding: 60px 0px;
}
.ind-any {
    padding: 80px 0px 110px 0px;
    @media (max-width: $breakpoints-5) {
        padding: 40px 0px 60px 0px;
    }
}
.ind-block {
    padding: 40px 0px 0px 0px;
}
.ind {
    padding: 100px 0px;
    @media (max-width: $breakpoints-1) {
        padding: 80px 0px;
    }
    @media (max-width: $breakpoints-4) {
        padding: 60px 0px;
    }
    @media (max-width: $breakpoints-5) {
        padding: 40px 0px;
    }
}
.scroll {
    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
        background-color: #ececec;
        border-radius: 16px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--helper-color-any);
        border-radius: 10px;
    }
}
.line-th {
    text-decoration: line-through;
}
.decoration {
    text-decoration: underline;
}
.logo {
    display: block;
    width: 200px;
    height: 80px;
    @media(max-width: $breakpoints-1) {
        width: 150px;
        height: 60px;
    }
    @media(max-width: $breakpoints-5) {
        width: 120px;
        height: 40px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

img {
    max-width: 100%;
}
.body-lock {
    overflow: hidden;
}
//! Components big
.title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 28px;
    line-height: 123%;
    @media (max-width: $breakpoints-5) {
        font-size: 20px;
    }
}
.title-any {
    color: var(--color-secondary);
}
.title-primary {
    color: var(--color-title-primary);
}
.head {
    &--between {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width: $breakpoints-5) {
            flex-direction: column;
            align-items: flex-start;
            & > *:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
}
.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 53px;
    padding: 5px 38px;
    font-weight: 700;
    font-size: 16px;
    line-height: 112%;
    text-align: center;
    color: #ffffff;
    transition: var(--transition);
    background-color: #00348c;
    flex-shrink: 0;
    @media(max-width: $breakpoints-5) {
        min-height: 40px;
    }
    &:hover {
        background-color: #052357;
    }
}
.section-color {
    background-color: #f9f9f9;
}
.p-right {
    padding-right: 10px;
}
.btn-any {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    font-size: 18px;
    transition: var(--transition);
    background-color: transparent;
    min-height: 64px;
    padding: 10px 15px;
    line-height: 100%;
    flex-shrink: 0;
    min-width: 248px;
    color: #000000;
    @media (max-width: $breakpoints-5) {
        font-size: 16px;
        min-width: 200px;
        min-height: 50px;
    }
    &:hover {
        background-color: var(--color-hover);
        color: var(--color-secondary);
    }
}
//! Components big end


//! Components small 
.hidden {
    overflow: hidden;
}
.error {
    border: 2px #ff4019 solid !important;
}
.btn-reset {
    border: none;
    background: transparent;
    cursor: pointer;
}
//! Components small end 




