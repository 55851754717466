@font-face {
    font-family: "Segoe UI";
    font-display: swap;
    src: url("./Segoe UI Bold.woff") format("woff"), url("./Segoe UI Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Segoe UI";
    font-display: swap;
    src: url("./Segoe UI Italic.woff") format("woff"), url("./Segoe UI Italic.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Segoe UI";
    font-display: swap;
    src: url("./Segoe UI.woff") format("woff"), url("./Segoe UI.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Montserrat";
    font-display: swap;
    src: url("./Montserrat-Bold.woff") format("woff"), url("./Montserrat-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Montserrat";
    font-display: swap;
    src: url("./Montserrat-Medium.woff") format("woff"), url("./Montserrat-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Montserrat";
    font-display: swap;
    src: url("./Montserrat-Regular.woff") format("woff"), url("./Montserrat-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
  }