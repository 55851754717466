@import '../../styles/varibale.scss';
.population {
    &__items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -13.5px;
        gap: 27px 0px;
        @media(max-width: $breakpoints-2) {
            gap: 15px 0px;
            margin: 0 -7px;
        }
    }
    &__item {
        flex: 0 1 20%;
        padding: 0 13.5px;
        @media(max-width: $breakpoints-2) {
            flex: 0 1 25%;
            padding: 0 7px;
        }
        @media(max-width: $breakpoints-4) {
            flex: 0 1 33.3%;
        }
        @media(max-width: $breakpoints-5) {
            flex: 0 1 50%;
        }
        @media(max-width: $breakpoints-6) {
            flex: 0 1 100%;
        }
    }
}

.card-category {
    display: flex;
    flex-direction: column;
    height: 100%;
    &--big {
        .card-category__body {
            min-height: 210px;
            font-size: var(--size-big);
            @media(max-width: $breakpoints-5) {
                min-height: 150px;
            }
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--color-secondary);
        position: relative;
        z-index: 2;
        font-size: var(--size-any);
        min-height: 168px;
        height: 100%;
        padding: 20px;
        text-align: center;
        line-height: 130%;
        @media(max-width: $breakpoints-6) {
            min-height: 135px;
            padding: 10px;
        }
        p {
            transition: var(--transition);
        }
        &:hover {
            p {
                transform: translateY(-10px);
            }
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 52, 140, 0.7), rgba(0, 52, 140, 0.7));
            z-index: -1;
        }
    }
}