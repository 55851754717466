@import '../../styles/mixin.scss';
@import '../../styles/varibale.scss';
.footer {
    background-color: #000000;
    color: var(--color-secondary);
    padding: 70px 0px 100px 0px;
    @media(max-width: $breakpoints-1) {
        padding: 60px 0px 60px 0px;
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;
        gap: 40px 0px;
    }
    &__logo {
        margin-bottom: 20px;
    }

    &__copy {
        color: #b7b3b3;
        margin-bottom: 30px;
    }

    &__adress {
        max-width: 190px;
        line-height: 156%;
    }

    &__column {
        flex: 0 1 20%;
        padding: 0 20px;
        @media(max-width: $breakpoints-2) {
            flex: 0 1 25%;
        }
        @media(max-width: $breakpoints-3) {
            flex: 0 1 33.3%;
        }
        @media(max-width: $breakpoints-4) {
            flex: 0 1 50%;
        }
        @media(max-width: $breakpoints-6) {
            flex: 0 1 100%;
        }
    }

    &__title {
        font-size: 22px;
        line-height: 125%;
        font-weight: 500;
        margin-bottom: 20px;
        font-family: var(--font-secondary);
        @media(max-width: $breakpoints-4) {
            font-size: 18px;
            margin-bottom: 15px;
        }
    }

    &__list {
        color: #b7b3b3;
        & > *:not(:last-child) {
            margin-bottom: 11px;
        }
    }
    &__list-link {
        color: #b7b3b3;
        font-size: var(--size-secondary);
        transition: var(--transition);
        line-height: 127%;
        &:hover {
            color: #ffffff;
        }
    }
}

.item-block {
    color: #b7b3b3;
    line-height: 150%;
    font-family: var(--font-secondary);
    &:not(:last-child) {
        margin-bottom: 30px;
    }
    &__link {
        color: var(--color-secondary);
        font-size: 22px;
        font-weight: 400;
        transition: var(--transition);
        &:hover {
            color: var(--color-hover-any);
        }
        span {
            font-weight: 700;
        }
    }

    &__text {
        color: var(--color-secondary);
    }

    &__main-link {
        font-family: var(--font-primary);
        font-size: 22px;
        color: var(--color-secondary);
        transition: var(--transition);
        &:hover {
            color: var(--color-hover-any);
        }
    }

    &__social {
        margin-top: 38px;
    }
}
.social {
    display: flex;
    align-items: center;
    gap: 15px 35px;
}
