@import '../../../styles/varibale.scss';

// Feature
.feature {

    &__open {
        font-size: 22px;
        color: var(--color-light-any);
        text-decoration: underline;
        font-weight: 600;
        background-color: transparent;
        display: flex;
        align-items: center;
        transition: var(--transition);
        &.active {
            svg {
                transform: rotate(180deg);
            }
        }
        &:hover {
            color: var(--color-hover);
            svg path{
                fill: var(--color-hover)
            }
        }
        svg {
            transition: var(--transition);
            margin-left: 15px;
            margin-top: 5px;
        }
    }

    &__info {
    }
}

.info-feature {
    &__block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    &__item {
        flex: 0 1 50%;
        padding: 0 15px;
        display: flex;
        font-size: var(--size-secondary);
        line-height: 140%;
        margin: 0 -5px;
        @media (max-width: $breakpoints-3) {
            flex: 0 1 100%;
        }
        @media(max-width: $breakpoints-5) {
            font-size: 13px;
        }
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__text {
        flex: 0 1 50%;
        padding: 0 5px;
    }

    &__value {
        flex: 0 1 50%;
        text-align: left;
        font-weight: 600;
        padding: 0 5px;
    }
}
