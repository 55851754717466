@import '../../styles/varibale.scss';

.breadcrumb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 240px;
    background-color: rgba(0, 52, 140, 1);
    position: relative;
    z-index: 10;
    padding: 20px 0px;
    @media(max-width: $breakpoints-1) {
        min-height: 200px;
    }
    @media(max-width: $breakpoints-3) {
        min-height: 110px;
        background-position: right;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 52, 140, 0.8), rgba(0, 52, 140, 0.8));
    }
    &__list {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        position: relative;
        z-index: 2;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__item {
        font-size: var(--size-any);
        text-transform: uppercase;
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 700;
        flex-shrink: 0;
        color: rgba(255,255,255, 0.6);
        @media (max-width: $breakpoints-5) {
            text-transform: none;
        }
        & + .breadcrumb__item:before {
            content: "";
            background-image: url(./img/arrows-breadcrumb.svg);
            background-repeat: no-repeat;
            background-position: center;
            min-width: 25px;
            height: 100%;
            display: inline-block;
            margin: 0 17px;
        } 
    }
    &__link {
        transition: var(--transition);
        color: rgba(255,255,255, 0.6);
        &:hover {
            color: rgba(255,255,255, 1);
            text-decoration: underline;
        }
        &.active {
            text-decoration: none;
        }
    }
    &__title {
        margin-top: 13px;
        position: relative;
        z-index: 2;
        @media (max-width: $breakpoints-5) {
            text-transform: none;
        }
    }
}
