// Card product
@import '../../styles/varibale.scss';
@import '../../styles/mixin.scss';

.assort {
    &__items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -17.5px;
        gap: 34px 0px;
        @media(max-width: $breakpoints-3) {
            margin: 0 -10px;
            gap: 20px 0px;
        }
        
    }
    &__item {
        flex: 0 1 33.3%;
        padding: 0 17.5px;
        @media(max-width: $breakpoints-3) {
            flex: 0 1 50%;
            padding: 0 10px;
        }
        @media(max-width: $breakpoints-6) {
            flex: 0 1 100%;
        }
    }
    &__banner {
        margin-top: 34px;
        @media(max-width: $breakpoints-5) {
            margin-top: 20px;
        }
    }
    &__banner-image {
        position: relative;
        padding-bottom: 21.55%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

// Card Assort 
.card-assort {
    display: flex;
    flex-direction: column;
    height: 100%; 
    overflow: hidden;
    background-color: var(--helper-color-light);
    &:hover {
        .card-assort__image {
            transform: scale(1.07);
        }
    }
    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: var(--transition);
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px 20px 60px 20px;
        text-align: center;
        height: 100%;
        position: relative;
        @include adaptiv-value('min-height', 580, 150, 1);
        @media(max-width: $breakpoints-1) {
            padding: 20px 20px 30px 20px;
        }
        @media(max-width: $breakpoints-6) {
            padding: 10px 10px 20px 10px;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 52, 140, 0) 0%, #3C7FF1 127.07%);
            z-index: 1;
        }
    }
    &__text {
        @include adaptiv-value('font-size', 40, 16, 1);
        font-weight: 700;
        line-height: 132%;
        position: relative;
        z-index: 3;
        color: var(--color-secondary);
        max-width: 340px;
        margin: 0 auto;
    }
}
