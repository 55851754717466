@import '../../styles/varibale.scss';
@import '../../styles/mixin.scss';

.products-inner {

    &__row {
        display: flex;
        align-items: flex-start;
        @media(max-width: $breakpoints-3) {
            flex-direction: column;
            align-items: baseline;
        }
    }
    &__content {
        flex: 1 1 auto;
        width: 100%;
        padding-top: 30px;
        @media(max-width: $breakpoints-any) {
            padding-top: 0px;
        }
    }
}

[data-rmiz-wrap="visible"], [data-rmiz-wrap="hidden"] {
    width: 100%;
    height: 100%;
}
[data-rmiz-overlay] {
    img {
        background-color: #fff;
    }
}


