@charset 'UTF-8';
// Обнуление
* {
	padding: 0;
	margin: 0;
	border: 0;
}
*,*:before,*:after{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}

nav,footer,header,aside{display: block;}

html,body {
	height: 100%;
	width: 100%;
	font-size: var(--size-primary);
	line-height: 125%;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-family: var(--font-primary);
	color: var(--color-primary);
	font-weight: var(--font-weight);
	background-color: var(--bg-main-color);
	// scroll-behavior: smooth;
}
input,button,textarea {font-family: var(--font-primary); box-sizing: border-box;}

input::-ms-clear {display: none;}
button {cursor: pointer;display:block;}
button::-moz-focus-inner {padding:0;border:0;}
a, a:visited {text-decoration: none; display: block;}
a:hover {text-decoration: none;}
ul li {list-style: none;}
img {vertical-align: top;}

h1,h2,h3,h4,h5,h6 {font-size:inherit;font-weight: inherit;}

/* --------------- */