@import '../../styles/varibale.scss';

.notFound {
    min-height: 620px;
    margin-bottom: 50px;
    @media(max-width: $breakpoints-any) {
       min-height: 100%;
    }
    &__content {
        background-color: #000322;
        border-radius: 50%;
        width: 100%;
        max-width: 1400px;
        height: 1400px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -850px auto 0 auto;
        padding-top: 580px;
        @media(max-width: $breakpoints-any) {
            border-radius: 0px;
            padding: 100px 15px;
            margin: 0px;
            height: auto;
        }
    }
    &__row {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media(max-width: $breakpoints-5) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
    &__text {
        font-size: 211px;
        line-height: 70%;
        color: var(--color-secondary);
        font-weight: 700;
        margin-right: 45px;
        @media(max-width: $breakpoints-5) {
            margin-right: 0;
            margin-bottom: 30px;
            font-size: 150px;
        }
    }
    &__any {
        color: var(--color-secondary);
        font-size: 23px;
        max-width: 302px;
        line-height: 130%;
        & > *:not(:last-child) {
            margin-bottom: 25px;
        }
    }
    &__btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid #ffffff;
        min-height: 56px;
        padding: 10px 30px;
        font-size: var(--size-primary);
        width: 100%;
        transition: var(--transition);
        color: #ffffff;
        &:hover {
            background-color: #fff;
            color: #000;
        }
    }
}
