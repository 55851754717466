@import '../../styles/mixin.scss';
@import '../../styles/varibale.scss';
.any {
    background-color: var(--helper-color-light);
    padding: 15px 0px;
    @media(max-width: $breakpoints-1) {
        padding: 10px 0px;
    }
    @media(max-width: $breakpoints-5) {
        display: none;
    }
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__menu {
        flex: 1 1 auto;
    }
}
//? Menu-any
.menu-any {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px 0px;
    &__link {
        transition: var(--transition);
        color: var(--color-primary);
        &:not(:last-child) {
            margin-right: 79px;
            @media(max-width: $breakpoints-1) {
                margin-right: 50px;
            }
        }
        &:hover {
            color: var(--color-hover)
        }
    }
}

.info-link {
    display: flex;
    align-items: baseline;
    gap: 10px 45px;
    @media(max-width: $breakpoints-1) {
        gap: 10px 25px;
    }
    @media(max-width: $breakpoints-5) {
        gap: 10px;
    }
    &__item {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        position: relative;
        transition: var(--transition);
        color: var(--color-primary);
        @media(max-width: $breakpoints-5) {
            font-size: 12px;
            svg {
                width: 25px;
            }
        }
        &:hover {
            color: var(--color-hover);
            svg {
                fill: var(--color-hover);
            }
        }
        svg {
            fill: black;
            transition: var(--transition);
        }
    }
}
//? Language
.language {
    display: flex;
    position: relative;
    background-color: rgba(132, 133, 230, 0.5);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
        &:hover {
            .language__choice {
                color: var(--color-hover);
            }
            .language__arrows svg {
                fill: var(--color-hover)
            }
            .language__content {
                opacity: 1;
            }
        }
    &:after {
        content: '';
        position: absolute;
        top: 40px;
        left: 0;
        height: 10px;
        width: 100%;
        z-index: 2;
    }
    &__item {
        display: flex;
        align-items: center;
    }

    &__image {
        flex-shrink: 0;
        width: 23px;
        height: 16px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__content {
        position: absolute;
        top: 115%;
        left: 0;
        opacity: 0;
        transition: var(--transition);
        background-color: rgba(235, 227, 227, 1);
        border-radius: 10px;
        padding: 10px;      
        z-index: 10; 
        & > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__current {
      
    }
    &__choice {
        margin: 0 8px;
        line-height: 130%;
        transform: var(--transition);
        font-size: var(--size-primary);
        background-color: transparent;
        font-weight: 700;
    }
    &__arrows {
        flex-shrink: 0;
        svg {
            fill: black;
            transition: var(--transition);
        }
    }
}
  

//? header styles 
.header {
    background-color: #fff;
    transition: var(--transition);
    position: relative;
    &.scroll {
        box-shadow: 0px 0px 10px var(--helper-color-any);
    }
    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 22px 0px 18px 0px;
        @media(max-width: $breakpoints-1) {
            padding: 15px 0px;
        }
    }

    &__logo {
        margin-right: 95px;
        flex-shrink: 0;
        @media(max-width: $breakpoints-1) {
            margin-right: 30px;
        }
    }

    &__buttons {
        margin-right: 35px;
        @media (max-width: $breakpoints-ipad) {
            order: -1;
        }
        @media (max-width: $breakpoints-5) {
            order: 1;
            margin-right: 0px;
            margin-left: auto;
        }
    }
    &__info {
        margin-left: 65px;
        @media(max-width: $breakpoints-1) {
            margin-left: 30px;
        }
        @media(max-width: $breakpoints-ipad) {
            margin-left: auto;
        }
        @media(max-width: $breakpoints-5) {
            display: none;
        }
    }

    &__menu {
        border-top: 1px solid var(--color-silver);
    }
    &__search {
        flex: 1 1 auto;
        @media (max-width: $breakpoints-ipad) {
            flex: 1 1 100%;
            order: 3;
            margin-top: 5px;
        }
    }
}

.category-btn {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--helper-color-any);
    color: var(--color-secondary);
    min-height: 52px;
    padding: 10px 30px;
    width: 100%;
    transition: var(--transition);
    min-width: 237px;
    @media(max-width: $breakpoints-1) {
        min-width: 200px;
    }
    @media (max-width: $breakpoints-any) {
        min-width: auto;
        padding: 10px 15px;
        min-height: 48px;
    }
    @media(max-width: $breakpoints-5) {
        background-color: transparent;
        padding: 0px;
        min-height: auto;
        display: none;
        .category-btn__icon span {
            background-color: var(--color-primary);
        }
        
    }
    &.active {
        z-index: 1000;
        .category-btn__icon span {
            transform: scale(0);
            &:first-child{
                transform: rotate(-42deg);
                top: 5px;
            }
            &:last-child{
                transform: rotate(42deg);
                bottom: 7px;
            }
        }
    }
    @media (any-hover: hover) {
        &:hover {
            background-color: var(--color-hover);
        }
    }
    &__icon {
        margin-right: 10px;
        @media (max-width: $breakpoints-any) {
            margin-right: 0px;
        }
        span {
            transition: var(--transition);
            position: relative;
            display: block;
            width: 20px;
            height: 2px;
            background-color: #fff;
            border-radius: 10px;
            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }
    }

    &__text {
        font-weight: 600;
        font-size: var(--size-secondary);
        color: var(--color-secondary);
        @media (max-width: $breakpoints-any) {
            display: none;
        }
    }
}

.menu-mobile {
    @include burger;
//   display: none;
}

  

.count {
    background-color: var(--helper-color-any);
    color: var(--color-secondary);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    right: -5px;
    min-width: 23px;
    min-height: 23px;
    border-radius: 50%;
    font-weight: 700;
    padding: 0px 5px;
    flex-shrink: 0;
}
.menu {
    padding: 24px 0px 10px 0px;
    @media(max-width: $breakpoints-1) {
        padding: 15px 0px;
    }
    @media(max-width: $breakpoints-5) {
        padding: 12px 0px;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 -5px;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 15px;
        &::-webkit-scrollbar {
            background-color:  #c4c4c4;
            height: 5px;
            cursor: pointer;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #00348c;
        }
    }
    &__link {
        transition: var(--transition);
        padding: 0 5px;
        flex: none;
        color: var(--color-primary);
        white-space: nowrap;
        &:hover {
            color: var(--color-hover);
        }
    }
}
  
.mobile-bar {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--bg-main-color);
    z-index: 100;
    padding: 5px 15px; 
    box-shadow: 0px 0px 10px var(--helper-color-any);
    @media(max-width: $breakpoints-5) {
        display: block;
    }
    &__links {
        @media(max-width: $breakpoints-5) {
            justify-content: space-between;
        }
    }
}

  
  
  //? Mobile menu
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: var(--transition);
    overflow: auto;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    opacity: 0;
    visibility: visible;
    pointer-events: none;
    height: 100vh;
    display: flex;
    flex-direction: column;
    &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        .mobile-menu__body {
            transform: translateX(0px);
        }
    }
    &__body {
        width: 320px;
        flex: 1;
        background-color: #fff;
        transform: translateX(-320px);
        transition: var(--transition);
        padding: 20px 0px;
    }
  
    &__close {
        background-color: transparent;
        flex-shrink: 0;
    }
  
    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        padding: 0 15px;
    }
    &__item {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        &.active {
            .mobile-menu__icon svg {
                transform: rotate(180deg);
            }
        }
    }
  
    &__current {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 16px;
        line-height: 130%;
        font-weight: 700;
        margin-bottom: 10px;
        padding: 0 15px;
       
    }
  
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #000;
        flex-shrink: 0;
        margin-left: 10px;
        svg {
            width: 12px;
            transition: var(--transition);
        }
    }
  
    &__content {
        background-color: #f5f5f5;
        font-weight: 600;
        display: flex;
        flex-direction: column;
    }
  
    &__link {
        padding: 10px 15px;
        color: var(--color-primary);
        &--more {
            color: var(--helper-color-any);
        }
    }
  }
  