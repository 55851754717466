@import '../../styles/varibale.scss';
.authentication {
    &__content {
        &--width {
            max-width: 577px;
        }
    }

    &__buttons {
        margin-top: 50px;
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        .btn {
            min-width: 177px;
        }
    }

    &__link {
        margin-left: 33px;
        text-decoration: underline;
        transition: var(--transition);
        font-size: var(--size-secondary);
        line-height: 130%;
        color: var(--color-primary);
        &:hover {
            color: var(--color-hover);
            text-decoration: underline;
        }
    }

    &__any {
        display: flex;
        font-size: var(--size-secondary);
        line-height: 130%;
        a {
            padding-left: 5px;
            font-weight: 600;
            color: var(--color-light-any);
        }
    }
}



