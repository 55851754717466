@import '../../styles/varibale.scss';
.cart {

    &__row {
        display: flex;
        align-items: flex-start;
        @media(max-width: $breakpoints-any) {
            flex-direction: column;
            align-items: baseline;
        }
    }
    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__content {
        flex: 1 1 auto;
        width: 100%;
    }

    &__info {
        flex: 0 0 545px;
        margin-left: 87px;
        position: sticky;
        top: 30px;
        @media(max-width: $breakpoints-0) {
            flex: 0 0 430px;
            margin-left: 50px;
        }
        @media(max-width: $breakpoints-any) {
            margin-left: 0;
            width: 100%;
            margin-top: 50px;
            flex: 1 1 100%;
        }
    }
}
.item-cart {
    display: flex;
    align-items: center;
    position: relative;
    &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        margin-bottom: 50px;
        padding-bottom: 50px;
        @media(max-width: $breakpoints-1) {
          margin-bottom: 30px;
          padding-bottom: 30px;
        }
    }
    @media(max-width: $breakpoints-5) {
        flex-direction: column;
        align-items: flex-start;
    }
    &__start {
        display: flex;
        align-items: center;
        flex: 0 1 50%;
        padding-right: 20px;
        @media(max-width: $breakpoints-5) {
            flex: 0 1 100%;
            width: 100%;
            margin-bottom: 15px;
        }
    }
    &__image {
        flex: 0 0 122px;
        height: 122px;
        margin-right: 30px;
        @media(max-width: $breakpoints-1) {
            margin-right: 15px;
            flex: 0 0 100px;
            height: 100px;
        }
        @media(max-width: $breakpoints-5) {
            flex: 0 0 80px;
            height: 80px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__content {
        display: flex;
        flex: 1 1 auto;
    }

    &__desc {
        font-size: var(--size--big-any);
        font-weight: 600;
        color: var(--color-primary);
        line-height: 120%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @media(max-width: $breakpoints-1) {
            max-width: 340px;
        }
        @media(max-width: $breakpoints-5) {
            flex: 1 1 100%;
        }
    }

    &__any {
        display: flex;
        align-items: center;
        padding-right: 15px;
        margin-left: auto;
        @media(max-width: $breakpoints-5) {
            margin-left: 0;
            padding-right: 0px;
        }
       
    }

    &__count {
        margin-right: 60px;
        @media(max-width: $breakpoints-0) {
            margin-right: 30px;
        }
        @media(max-width: $breakpoints-5) {
            margin-right: 20px;
        }
    }

    &__price {
        font-size: var(--size--big-any);
        font-weight: 600;
        flex-shrink: 0;
        max-width: 170px;
        line-height: 130%;
        @media(max-width: $breakpoints-0) {
            font-size: 20px;
        }
        @media(max-width: $breakpoints-5) {
            font-size: 16px;
            max-width: 100%;
        }
    }

    &__delete {
        background-color: transparent;
        margin-left: auto;
        transition: var(--transition);
        will-change: transform;
        &:active {
            transform: scale(1.05);
        }
        @media(max-width: $breakpoints-4) {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}
.info-block {
    border: 1px solid var(--helper-color-any);
    padding: 55px 34px;
    @media(max-width: $breakpoints-any) {
        padding: 30px 15px;
    }
    &__wrapper {
        margin-bottom: 70px;
        @media(max-width: $breakpoints-any) {
            margin-bottom: 40px;
        }
    }
    &__promo {
        margin-bottom: 44px;
        @media(max-width: $breakpoints-any) {
            margin-bottom: 20px;
        }
    }
    &__item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: var(--size--salable);
        margin: 0 -10px;
        @media(max-width: $breakpoints-5) {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }

    &__text {
        padding: 0 10px;
        max-width: 300px;
    }

    &__value {
        font-weight: 600;
        padding: 0 10px;
        text-align: right;
    }
    &__btn {
        width: 100%;
        &.btn {
            min-height: 62px;
            font-size: var(--size-secondary);
            color: var(--color-secondary);
        }
    }
}

.notProducts {
    text-align: center;
    svg {
        width: 150px;
    }
    p {
        font-weight: 700;
        font-size: 20px;
        margin-top: 20px;
        text-align: center;
    }
}