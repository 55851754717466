@import '../../styles/varibale.scss';
.products {
    &__slider {
        position: relative;
        .swiper-button-prev {
            left: -25px;
            @media(max-width: $breakpoints-3) {
                left: -5px;
            }
        }
        .swiper-button-prev, .swiper-button-next {
            @media(max-width: $breakpoints-3) {
                top: 45%;
            }
        }
    }
    &__tabs {
        position: absolute;
        top: 37px;
        right: 32px;
        gap: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media(max-width: $breakpoints-3) {
            flex-direction: row;
            align-items: center;
        }
        @media(max-width: $breakpoints-6) {
            flex-direction: column;
            top: 25px;
            right: 25px;
        }
    }
    &__tabs-btn {
        text-decoration: underline;
        transition: var(--transition);
        background-color: transparent;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        font-size: 16px;
        line-height: 100%;
        &:hover {
            color: var(--color-secondary);
        }
        &.active {
            color: var(--color-secondary);
            text-decoration: none;
        }
    }
}