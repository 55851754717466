.btnScrollUp {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--helper-color-light);
    transition: var(--transition);
    svg path {
        transition: var(--transition);
    }
    &:hover {
        background-color: var(--helper-color-any);
        svg path {
            fill: #ffffff;
        }
      
    }
}