@import '../../../styles/varibale.scss';


.content-products {
    &__title {
        font-weight: 600;
        font-size: 34px;
        line-height: 150%;
        color: #000322;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
        @media(max-width: $breakpoints-1) {
            font-size: 28px;
        }
        @media(max-width: $breakpoints-5) {
            font-size: 20px;
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
       
    }

    &__desc {
        margin-bottom: 50px;
        line-height: 160%;
        font-size: var(--size-secondary);
        @media(max-width: $breakpoints-1) {
            margin-bottom: 20px;
            font-size: 16px;
        }
    }

    &__footer {
        & > *:not(:last-child) {
            margin-bottom: 20px;
            @media(max-width: $breakpoints-5) {
                margin-bottom: 10px;
            }
        }
    }

    &__price {
        font-size: 34px;
        font-weight: 600;
        color: var(--color-light-any);
        line-height: 150%;
        @media(max-width: $breakpoints-5) {
            font-size: 20px;
        }
    }

    &__buttons {
        display: flex;
        gap: 10px;
        
    }
    &__buy {
        min-width: 221px;
        min-height: 58px;
        @media(max-width: $breakpoints-6) {
            min-width: 160px;
        }
    }
     &__any {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 58px;
        background-color: rgba(0, 3, 34, 0.05);
        transition: var(--transition);
        &:hover {
            background-color: rgba(0, 3, 34, 0.09);
        }
    }
}
