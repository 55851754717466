

.search-page {
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__total {
    font-weight: 600;
    color: var(--color-light-any);
    font-size: var(--size-big);
  }
  &__title {
    margin-bottom: 10px;
  }
  &__items {
    margin-bottom: 60px;
  }
  &__preloader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.rc-pagination {
  &-item {
    font-weight: 600;
    color: var(--color-light-any);
    &:not(:last-child) {
      margin-right: 5px;
    }
    &-active {
      background-color: var(--helper-color-any);
      color: var(--color-secondary);
    }
  }
  &-jump-next {
    margin-right: 5px;
  }
  &-prev {
    margin-right: 5px;
  }
  &-prev, &-next {
    font-size: 18px;
  }
}

.rc-pagination-prev, .rc-pagination-next, .rc-pagination-jump-next, .rc-pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
  width: 50px;
  height: 50px;
  border: 1px solid var(--helper-color-any);
  &:hover {
    background-color: var(--helper-color-any);
    color: var(--color-secondary);
  }
}


.search-notFound {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  &__wrapper {
    width: 100%;
    max-width: 350px;
    height: 350px;
  }
  &__image {
      margin-bottom: 10px;
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  &__text {
    font-size: var(--size-primary);
    color: var(--color-light-any);
    line-height: 120%;
    font-weight: 600;
    font-family: var(--font-secondary);
  }

}
