:root {
    /* //? Font size vars  */
    --size-primary: 16px;
    --size-secondary: 18px;
    --size-any: 20px;
    --size-big: 32px;
    --size--big-any: 24px;
    --size--salable: 22px;

    /* //? Colors vars  */
    --color-primary: #000000;
    --color-secondary: #ffffff;
    --color-silver: #ededed;
    --color-light-any: #00348c;
    --color-silver-light: #8c8c8c;
    --color-hover: #021c4b;
    --color-hover-any: #b7b3b3;

    /* //? Helpers vars  */
    --helper-color-light: #ededed;
    --helper-color-white: #ffffff;
    --helper-color-any: #00348c;
    --helper-color-danger: #ed1c24;

    /* //? Colors title vars */
    --color-title-primary:  #00348c;
    --color-title-any: #ffffff;

    --bg-main-color: #ffffff;

    /* //? Font Family vars  */
    --font-primary: 'Segoe UI', sans-serif;
    --font-secondary: 'Montserrat', sans-serif;
    --font-weight: 400;

    /* //? Any components vars  */
    --transition: all .3s ease;
    --btn-bg-any: rgba(0, 3, 34, 0.05);
}

$breakpoints-any: 1300px;
$breakpoints-ipad: 1100px;
$breakpoints-0: 1800px;
$breakpoints-1: 1600px;
$breakpoints-2: 1400px;
$breakpoints-3: 1200px;
$breakpoints-4: 992px;
$breakpoints-5: 767px;
$breakpoints-6: 575px;
@media (max-width: $breakpoints-1) {
    :root {
        --size--big-any: 20px;
        --size-any: 18px;
        --size-big: 25px;
        --size--salable: 18px;
    }
}
@media (max-width: $breakpoints-5) {
    :root {
        --size-primary: 14px;
        --size-secondary: 15px;
        --size-any: 16px;
        --size--big-any: 16px;
        --size-big: 22px;
        --size--salable: 16px;
    }
} 