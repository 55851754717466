@import '../../../styles/varibale.scss';
.search {
    &__form {
        position: relative;
    }

    &__input {
        position: relative;      
    }
    &__control {
        border: 2px solid #00348C;
        min-height: 52px;
        padding: 5px 65px 5px 20px;
        width: 100%;
        font-size: 16px;
        @media(max-width: $breakpoints-5) {
            min-height: 40px;
        }
        &::placeholder {
            color: #717171;
        }
    }

    &__btn {
        background-color: #00348c;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        min-width: 57px;
        transition: var(--transition);
        @media(max-width: $breakpoints-5) {
            min-width: 45px;
        }
        svg {
            will-change: transform;
            transition: var(--transition);
        }
        &:hover {
            background-color: var(--color-hover);
            svg {
                
                transform: scale(1.09);
            }
        }
    }
    &__items {
        position: absolute;
        left: 0;
        top: 100%;
        background-color: #fff;
        width: 100%;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        z-index: 1000;
        transition: var(--transition);
        padding: 10px;
    }
    &__prealoder {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__wrapper {
        padding: 10px;
    }
    &__list {
        max-height: 440px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 15px;
        @media(max-width: $breakpoints-5) {
            max-height: 250px;
        }
    }
   
}

.search__form:not(:focus-within) {
    .search__items {
        display: none;
    }
}