
@import '../../styles/varibale.scss';
.card-product {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    &--style {
        .card-product__body {
            padding: 40px 30px;
            @media(max-width: $breakpoints-5) {
                padding: 20px 15px;
            }
        }
        .card-product__image {
            height: 240px;
            margin: 0 auto 29px auto;
            @media(max-width: $breakpoints-5) {
                height: 180px;
                margin: 0 auto 20px auto;
            }
        }
        .card-product__title {
            font-size: 19px;
        }
        .card-product__content {
            margin-bottom: 22px;
        }
        .card-product__btn.btn {
            min-height: 45px;
            min-width: 135px;
            font-size: 14px;
        }
        .price-fix {
            font-size: var(--size-secondary);
        }
    }
    &:hover {
        .card-product__image {
            transform: scale(1.06);
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;
        position: relative;
        padding: 47px;
        @media (max-width: $breakpoints-0) {
            padding: 25px 20px;
        }
        @media(max-width: $breakpoints-5) {
            padding: 25px 15px;
        }
    }

    &__badge {
        position: absolute;
        top: 13px;
        right: 10px;
        z-index: 10;
    }

    &__image {
        display: block;
        margin: 0 auto 24px auto;
        width: 100%;
        height: 280px;
        transition: var(--transition);
        will-change: transform;
        @media(max-width: $breakpoints-1) {
            margin: 0 auto 10px auto;
            height: 220px;
        }
        @media(max-width: $breakpoints-3) {
            height: 180px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__content {
        margin-bottom: 30px;
        @media(max-width: $breakpoints-1) {
            margin-bottom: 15px;
        }
    }

    &__desc {
        display: block;
        color: var(--color-primary);
        & > *:not(:last-child) {
            margin-bottom: 9px;
        }
    }
    &__stock {
        margin-top: 20px;
        @media(max-width: $breakpoints-1) {
            margin-top: 15px;
        }
    }
    &__title {
        font-size: var(--size--salable);
        text-decoration: underline;
        line-height: 131%;
        transition: var(--transition);
        &:hover {
            color: var(--color-hover);
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
        @media(max-width: $breakpoints-1) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__price {
        & > *:not(:last-child) {
            margin-bottom: 7px;
        }
    }
    &__btn {
        &.btn {
            min-width: 155px;
            @media(max-width: $breakpoints-1) {
                min-width: auto;
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}
.price-fix {
    display: flex;
    align-items: center;
    font-size: var(--size-any);
    font-weight: 600;
}
.price-mounth {
    font-size: var(--size-primary);
    color: #878787;
    line-height: 120%;
    // text-decoration: line-through;
}