.itemSearch {
    display: flex;
    align-items: center;
}
.itemSearch:not(:last-child) {
    margin-bottom: 10px;
}
.itemSearch:hover .itemSearch__price, .itemSearch:hover .itemSearch__text{
    color: var(--color-hover);
}
.itemSearch__link {
    display: flex;
    align-items: center;
    width: 60%;
    flex: 1 1 auto;
    font-size: 15px;
    padding-right: 20px;
}
.itemSearch__link img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.itemSearch__price {
    margin-left: auto;
    font-weight: 700;
    color: var(--color-primary);
}
.itemSearch__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: var(--color-primary);
    max-width: 500px;
}

