@import './varibale.scss';
.grid-primary {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    &__item {
        padding: 0 5px;
        flex: 0 1 33.333%;
        margin-bottom: 10px;
    }
}

.grid-half {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 45px;
}

.grid-four {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 33px;
    grid-row-gap: 33px;
    @media(max-width: $breakpoints-3) {
        grid-template-columns: repeat(3,1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }
    @media(max-width: $breakpoints-4) {
        grid-template-columns: repeat(2,1fr);
    }
    @media(max-width: $breakpoints-5) {
        grid-template-columns: repeat(1,1fr);
    }
}
.grid-three {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 30px;
    @media(max-width: $breakpoints-3) {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px
    }
    @media(max-width: $breakpoints-4) {
        grid-template-columns: repeat(2,1fr);
    }
    @media(max-width: $breakpoints-5) {
        grid-template-columns: repeat(1,1fr);
    }
}