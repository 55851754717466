.swiper-button-next, .swiper-button-prev {
    &:after {
        display: none;
    }
}
.swiper-slide {
    height: auto;
}
.swiper-pagination-bullet {
    background-color: #ffffff;
    width: 83px;
    height: 2.7px;
    opacity: 1;
    border-radius: 0px;
    @media(max-width: $breakpoints-1) {
        width: 60px;
    }
    @media(max-width: $breakpoints-5) {
        width: 35px;
    }
    &-active {
        background-color: black;
    }
}
//? Arrows slider 
.arrow-any {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #f0f0f0;
    transition: var(--transition);
    @media(max-width: $breakpoints-5) {
        width: 35px;
        height: 35px;
        svg {
            width: 8px;
        }
    }
    svg {
        fill: #ffffff;
        transition: var(--transition);
    }
    &:hover {
        background-color: #eeeeee;
        svg {
            fill: #00348C;
        }  
    }
}
.arrow-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--helper-color-white);
    transition: var(--transition);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    @media(max-width: $breakpoints-1) {
        width: 54px;
        height: 54px;
        svg {
            width: 12px;
        }
    }
    @media(max-width: $breakpoints-5) {
        width: 35px;
        height: 35px;
        svg {
            width: 7px;
        }
    }
    &:hover {
        background-color:#e9e7e7;
    }
}
.page {

		&__row {
            display: flex;
            align-items: flex-start;
            @media(max-width: $breakpoints-3) {
                flex-direction: column;
                align-items: stretch;
            }
		}

		&__block {
            flex: 0 0 403px;
            width: 100%;
            background-color: var(--helper-color-any);
            padding: 30px 50px 50px 50px;
            color: var(--color-secondary);
            margin-right: 30px;
            position: relative;
            @media(max-width: $breakpoints-1) {
                flex: 0 0 320px;
                padding: 25px;
            }
            @media(max-width: $breakpoints-3) {
                flex: 1 1 100%;
                margin-bottom: 30px;
                margin-right: 0px;
            }
            & > *:not(:last-child) {
                margin-bottom: 30px;
            }
		}
        &__title {
            max-width: 230px;
            font-size: 28px;
            line-height: 123%;
            color: var(--color-title-any);
            font-weight: 700;
            text-transform: uppercase;
            @media(max-width: $breakpoints-1) {
                font-size: 24px;
                max-width: 190px;
            }
            @media(max-width: $breakpoints-5) {
                font-size: 20px;
            }
        }
		&__link {
            display: flex;
            align-items: center;
            font-size: var(--size-secondary);
            color: var(--color-secondary);
            @media(max-width: $breakpoints-5) {
                font-size: 14px;
            }
            svg {
                margin-left: 14px;
            }
		}

		&__content {
            flex: 1 1 auto;
            min-width: 0px;
		}
}

.badge {
    display: flex;
    flex-direction: column;
    & > *:not(:last-child) {
        margin-bottom: 12px;
    }
    &-item {
        background-color: transparent;
        &.active {
            svg, path, rect {
                fill: #e30909;
            }
        }
        svg, path, rect {
            transition: var(--transition);
        }
        &:hover {
            svg path, svg rect {
                fill: #e30909;
            }
        }
    }
}




.tabs-content {
    display: none;
    &.active {
        display: block;
    }
}

.tabs {
    display: flex;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    &__btn {
        font-size: var(--size-any);
        background-color: transparent;
        line-height: 180%;
        transition: var(--transition);
        position: relative;
        padding: 0 75px 20px 75px;
        flex-shrink: 0;
        @media(max-width: $breakpoints-3) {
            padding: 0 35px 20px 35px;
        }
        @media(max-width: $breakpoints-5) {
            padding: 0 15px 10px 15px;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--helper-color-any);
            opacity: 0;
            transition: var(--transition);
        }
        &:hover {
            color: var(--color-hover);
            &:after {
                opacity: 1;
            }
        }
        &.active {
            color: var(--color-light-any);
            font-weight: 600;
            &:after {
                opacity: 1;
            }
        }
    }
}
//? Cabinet nav
.nav {
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    &__link {
        display: block;
        font-size: var(--size-any);
        background-color: transparent;
        line-height: 180%;
        transition: var(--transition);
        position: relative;
        padding: 0 75px 20px 75px;
        flex-shrink: 0;
        color: var(--color-primary);
        @media(max-width: $breakpoints-3) {
            padding: 0 35px 20px 35px;
        }
        @media(max-width: $breakpoints-5) {
            padding: 0 15px 10px 15px;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--helper-color-any);
            opacity: 0;
            transition: var(--transition);
        }
        &:hover {
            color: var(--color-hover);
            &:after {
                opacity: 1;
            }
        }
        &.active {
            color: var(--color-light-any);
            font-weight: 600;
            &:after {
                opacity: 1;
            }
        }
    }
}
//? CashBack
.cashback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 5px solid var(--color-light-any);
    min-height: 207px;
    padding: 15px;
    font-size: var(--size-any);
    text-align: center;
    @media(max-width: $breakpoints-5) {
        min-height: 150px;
    }
    &__total {
        color: var(--color-light-any);
        font-size: 26px;
        line-height: 130%;
        font-weight: 600;
    }
}
//? Icon
.icon {
    &:hover {
        svg , rect, path{
            fill:var(--helper-color-danger); 
        }
    }
    svg, rect, path {
        transition: var(--transition);
        fill:#B3B3B3;
    }
    &--active {
        svg, rect, path {
            fill:var(--helper-color-danger);
        }
    }
}


//? custom inputs
.checkbox .checkbox__input {
    position: absolute;
    right: 100vw;
    width: 0px;
}

.checkbox {
    &__wrapper {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        font-size: var(--size-primary);
        border: 2px solid rgba(0, 3, 34, 0.1);
        width: 100%;
        min-height: 52px;
        padding: 10px 20px;
        background-color: transparent;
        cursor: pointer;
        @media(max-width: $breakpoints-2) {
            min-height: 45px;
        }
    }
    .checkbox__label .checkbox__input:checked + .checkbox__wrapper {
        border: 2px solid var(--helper-color-any);
        color: var(--helper-color-any);
        .checkbox__value {
            color: var(--helper-color-any);
        }
    }
    &__text {
        font-weight: 600;
        font-size: var(--size-secondary);
    }
    &__value {
        color: #949494;
        padding-left: 40px;
        @media(max-width: $breakpoints-2) {
            padding-left: 25px;
        }
    }
}

.check-radio{

    &__item {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 45px;
        cursor: pointer;
        font-size: 21px;
        user-select: none;
        font-weight: 500;
        line-height: 140%;
    }

    &__input {
        position: absolute;
        opacity: 0;
    }

    &__span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 29px;
        width: 29px;
        cursor: pointer;
        transition: var(--transition);
        border: 2.5px solid #000000;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 17px;
            height: 17px;
            background-color: var(--helper-color-any);
            opacity: 0;
            transition: var(--transition);
            border-color: var(--helper-color-any);
        }
    }
}
.check-any {
    &__item {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 45px;
        cursor: pointer;
        font-size: var(--size-primary);
        user-select: none;
        font-weight: 500;
        line-height: 140%;
        a {
            text-decoration: underline;
            display: inline;
            padding-left: 5px;
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
    }
    &__count {
        font-size: var(--size-primary);
        color: #949494;
        margin-left: 40px;
    }
    &__span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 25px;
        width: 25px;
        cursor: pointer;
        transition: var(--transition);
        border: 2.5px solid #DFDFDF;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            height: 100%;
            background-color: var(--helper-color-any);
            opacity: 0;
            transition: var(--transition);
        }
    }
}
.check-radio__input:checked ~ .check-radio__span {
    border-color: var(--helper-color-any);
}
.check-radio__input:checked ~ .check-radio__span:after {
    opacity: 1;
}
.check-any__input:checked ~ .check-any__span {
    border: none;
}
.check-any__input:checked ~ .check-any__span:after {
    opacity: 1;
}

//? count-main 
.count-main {
    display: flex;
    width: 201px;
    @media(max-width: $breakpoints-2) {
        width: 150px;
    }
    &__button {
        width: 67px;
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--helper-color-any);
        color: #ffffff;
        flex-shrink: 0;
        font-size: 26px;
        transition: var(--transition);
        @media(max-width: $breakpoints-2) {
           width: 50px;
           height: 50px;
           font-size: 20px;
        }
        &:hover {
            background-color: var(--color-hover);
        }
    }

    &__value {
        display: flex;
        align-items: center;
        text-align: center;
        width: 67px;
        border: 2px solid var(--helper-color-any);
        color: var(--color-light-any);
        font-size: 26px;
        @media(max-width: $breakpoints-2) {
            width: 50px;
            font-size: 20px;
         }
    }
}

//? Send any input 

.send-any {
    position: relative;
    &__input {
        background-color: #ffffff;
        border: 1px solid rgba(0, 3, 34, 0.2);
        font-size: 18px;
        padding: 10px 80px 10px 25px;
        min-height: 63px;
        width: 100%;
        transition: var(--transition);
        @media(max-width: $breakpoints-5) {
            min-height: 50px;
            font-size: 16px;
        }
        &:focus {
            border-color: var(--helper-color-any);
        }
        &::placeholder {
            font-size: 18px;
            color: rgba(0, 3, 34, 0.5);
        }
    }
    &__btn {
        background-color: var(--helper-color-any);
        min-width: 69px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        transition: var(--transition);
        &:hover {
            background-color: var(--color-hover);
        }
    }
}

//? Accordion
.accordion  {
    width: 100%;
    &__content {
        max-height: 0;
        overflow: hidden;
        transition: max-height .2s ease-out;
    }
    &.active {
        .accordion__icon svg {
            transform: rotate(180deg);
        }
    }
    &__icon {
        margin-left: 38px;
        svg {
            transition: var(--transition);
        }
    }
}
.hide-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-out
}


//? Table style  
.table-wrap{
	overflow-x:auto;
    padding-bottom: 10px;
}	
.table {
    border-collapse: collapse;
    width: 100%;
    background-color: #f9f9f9;
    font-size: var(--size--big-any);
    font-weight: 600;
    td {
        border: 5px solid #fff;
        height: 100px;
        padding: 0px 15px 0px 60px;
        line-height: 130%;
        white-space: nowrap;
        @media(max-width: $breakpoints-any) {
            padding: 0 15px 0px 15px;
            height: 70px;
        }
    }
    &-head {
        td {
            font-size: var(--size-any);
            color: #545454;
            font-weight: 400;
        }
       
    }
}


//? Style for form 
.form {

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;
        gap: 40px 0px;
        @media(max-width: $breakpoints-2) {
            gap: 30px 0px;
            margin: 0 -10px;
        }
    }

    &__item {
        padding: 0 20px;
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @media(max-width: $breakpoints-2) {
            padding: 0 10px;
        }
        @media(max-width: $breakpoints-5) {
            flex: 1 1 100%;
        }
        &--grow {
            flex: 0 1 100%;
        }
        &--small {
            flex: 0 1 33.3%;
            @media(max-width: $breakpoints-5) {
                flex: 1 1 100%;
            }
        }
    }

    &__label {
        display: block;
        margin-bottom: 10px;
        line-height: 100%;
        font-size: var(--font-secondary);
    }
}

.form-control {
        border: 1px solid rgba(0, 3, 34, 0.3);
        min-height: 62px;
        padding: 5px 23px;
        font-size: 18px;
        color: var(--color-primary);
        transition: var(--transition);
        width: 100%;
        @media(max-width: $breakpoints-5) {
            min-height: 45px;
            font-size: 16px;
            padding: 5px 15px;
        }
        &:focus {
            border-color: var(--helper-color-any);
        }
        &::placeholder {
            color:#797979;
        }
}



//? Countdown 
.countdown {
    &__text {
        color: #2f2f2f;
        margin-bottom: 10px;
    }
    &__wrapper {
        display: flex;
        width: 270px;
    }
    &__time {
        font-size: 12px;
        color: var(--color-primary);
        position: relative;
        line-height: 100%;
        &:not(:last-child) {
            margin-right: 50px;
            @media(max-width: $breakpoints-1) {
                margin-right: 30px;
            }
            @media(max-width: $breakpoints-5) {
                margin-right: 15px;
            }
        }
        &:not(:last-child):after {
            content: ':';
            position: absolute;
            top: 0;
            right: -22px;
            color: var(--color-light-any);
            font-size: var(--size--salable);
            @media(max-width: $breakpoints-1) {
                right: -15px;
            }
            @media(max-width: $breakpoints-5) {
                display: none;
            }
        }
    }

    &__count {
        color: var(--color-light-any);
        font-size: var(--size--salable);
        margin-bottom: 5px;
	}
}

//? Info order sidebar
.info-order {
    border: 5px solid var(--helper-color-any);
    padding: 60px 45px;
    @media(max-width: $breakpoints-1) {
        padding: 50px 30px;
    }
    @media(max-width: $breakpoints-5) {
        padding: 30px 15px;
    }
    &__number {
        font-size: var(--size--salable);
        font-weight: 600;
        color: var(--color-light-any);
        line-height: 130%;
        margin-bottom: 40px;
    }
    &__list-item {
        display: flex;
        justify-content: space-between;
        margin: 0 -10px;
        font-size: var(--size--salable);
        line-height: 130%;
        &:not(:last-child) {
            margin-bottom: 12px;
        }
        span {
            padding: 0 10px;
           
        }
    }
    &__list-value {
        font-weight: 600;
        text-align: right;
    }
    &__wrapper {
        margin-bottom: 55px;
        @media(max-width: $breakpoints-5) {
            margin-bottom: 25px;
        }
    }

    &__item {
        font-size: var(--size--salable);
        line-height: 130%;
        &:not(:last-child) {
            margin-bottom: 25px;
        }
        @media(max-width: $breakpoints-5) {
            font-size: 16px;
        }
    }

    &__desc {
        font-weight: 600;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    &__any {
        &:not(:last-child) {
            margin-bottom: 60px;
            @media(max-width: $breakpoints-5) {
                margin-bottom: 30px;
            }
        }
    }
    &__offer {
        margin-bottom: 22px;
        a {
            text-decoration: underline;
        }
    }
    &__any-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: var(--size--salable);
        line-height: 130%;
        margin: 0 -10px;
        &:not(:last-child) {
            margin-bottom: 12px;
        }
        span {
            display: block;
            padding: 0 10px;
            &:nth-child(2) {
                color: var(--color-light-any);
                font-weight: 600;
            }
        }
    }

    &__footer {
        text-align: center;
    }

    &__btn {
        width: 100%;
    }
}
