@import '../../../styles/varibale.scss';
@import '../../../styles/mixin.scss';
.product-slider {
    display: flex;
    align-items: flex-start;
    flex: 0 0 730px;
    min-width: 0px;
    margin-right: 70px;
    @media(max-width: $breakpoints-1) {
        margin-right: 40px;
        flex: 0 0 600px;
    }
    @media(max-width: $breakpoints-3) {
        flex: 1 1 100%;
        width: 100%;
        margin-bottom: 30px;
        margin-right: 0px;
        position: static!important;
    }
    @media(max-width: $breakpoints-5) {
        flex-direction: column-reverse;
    }
   &__main {
        width: 100%;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.2);
        @include adaptiv-value('height', 555, 320, 1);
        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
   }
   &__zoom {
        position: absolute;
        bottom: 30px;
        right: 30px;
   }
   &__gallery {
        margin-right: 30px;
        flex: 0 0 98px;
        @media(max-width: $breakpoints-5) {
            flex: 1 1 auto;
            width: 100%;
            margin-top: 20px;
            margin-right: 0px;
        }
        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 91px!important;
            border: 1px solid rgba(0, 0, 0, 0.2);
            transition: var(--transition);
            cursor: pointer;
            padding: 5px;
            &-thumb-active {
                border: 1px solid var(--helper-color-danger);
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
   }
}