@import '../../styles/varibale.scss';
.card-category {
    display: flex;
    flex-direction: column;
    height: 100%;
    &--big {
        .card-category__body {
            min-height: 210px;
            font-size: var(--size-big);
            @media(max-width: $breakpoints-5) {
                min-height: 150px;
            }
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--color-secondary);
        position: relative;
        z-index: 2;
        font-size: var(--size-any);
        min-height: 168px;
        height: 100%;
        padding: 20px;
        text-align: center;
        line-height: 130%;
        @media(max-width: $breakpoints-6) {
            min-height: 135px;
            padding: 10px;
        }
        p {
            transition: var(--transition);
        }
        &:hover {
            p {
                transform: translateY(-10px);
            }
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 52, 140, 0.7), rgba(0, 52, 140, 0.7));
            z-index: -1;
        }
    }
}