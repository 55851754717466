
// Адаптивное свойство (формула)
$maxWidth: 1920;
$maxWidthContainer: 1800;

 @mixin adaptiv-value($property, $startSize, $minSize, $type) {
    $addSize: $startSize - $minSize;
    @if $type==1 {
        // Только если меньше ширины контейнера
      #{$property}: $startSize + px;
      @media (max-width: #{$maxWidthContainer + px}) {
          #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidthContainer - 320}));
      }
      // Только больше минимальной ширины контейнера
    } 
}

@mixin burger {
  display: none;
  @media (max-width: $breakpoints-5) {
       display: block;
       position: relative;
       width: 38px;
       height: 38px;
       z-index: 100;
       background-color: transparent;
        span {
           top: 17px; 
           right: 0;
           position: absolute; 
           width: 100%;
           height: 3px; 
           background-color: #000000;
           &:first-child { 
               top: 9px;
            }
           &:last-child{
               top: auto; 
               bottom: 10px; 
               width: 100%;
               right: 0;
          }
          transition: var(--transition);
       }
       &.active {
           span{
               transform: scale(0);
               &:first-child{
                   transform: rotate(-42deg);
                   top: 19px;
               }
               &:last-child{
                   transform: rotate(42deg);
                   bottom: 16px;
                   width: 100%;
               }
           }      
      }
  }
}


@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
