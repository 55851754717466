@import '../../styles/mixin.scss';
@import '../../styles/varibale.scss';
.registration-enter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 35px;
    @media(max-width: $breakpoints-6) {
        gap: 35px 15px;
        grid-template-columns: repeat(1, 1fr);
    }
    &--width {
        max-width: 883px;
        width: 100%;
    }
}
.registration {
    &__form {
        max-width: 730px;
        .form__row {
            margin: 0px -10px;
        }
        .form__item {
            padding: 0 10px;
        }
    }
    &__form-footer{
        margin-top: 38px;
    }
    &__form-btn {
        width: 100%;
        max-width: 306px;
        margin-top: 40px;
    }
}
.item-registration {
    display: flex;
    flex-direction: column;
    height: 100%;
    &__body {
        display: flex;
        flex-direction: column;
        font-size: 26px;
        line-height: 125%;
        height: 100%;
        color: var(--color-light-any);
        @media(max-width: $breakpoints-6) {
            font-size: 20px;
        }
        &:hover {
            .item-registration__wrapper {
                border-color: var(--helper-color-danger);
                svg path {
                    fill: var(--helper-color-danger);
                }
            }
            .item-registration__text  {
                color: var(--helper-color-danger);
            }
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border: 2px solid var(--helper-color-any);
        transition: var(--transition);
        @include adaptiv-value('min-height', 416, 220, 1);
        @media(max-width: $breakpoints-5) {
           svg {
               width: 70px;
           }
        }
        svg path {
            transition: var(--transition);
        }
    }

    &__text {
        margin-top: 20px;
        text-decoration: underline;
        transition: var(--transition);
        @media(max-width: $breakpoints-6) {
            margin-top: 15px;
        }
    }
}